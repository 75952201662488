body {
  margin: 0;
}

.options {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.option-buttons {
  margin-bottom: 8px !important;
  width: 100%;
}

.home-container {
  height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .option-buttons {
    width: 380px;
    margin-bottom: 8px !important;
  }
}
